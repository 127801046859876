<template>
  <div id="background">
    <div>
      <img style="height: 35px" src="../assets/yuni_logo_white.svg" alt />
    </div>
    <div style="min-width: 135px"></div>
    <div class="botlinks">
      <router-link v-if="!mobileView" to="/legal-notice" class="navitem"
        ><div class="links">Legal notice</div></router-link
      >
      <div style="min-width: 50px; height: 25px"></div>
      <router-link v-if="!mobileView" to="/privacy-policy" class="navitem"
        ><div class="links">Privacy policy</div></router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.links {
  color: white;
}

#background {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #2b2a35;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding-right: 105px;
  padding-left: 105px;
  height: 150px;
}

#title {
  font-size: 35px;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
}

.row {
  display: flex;
}

.botlinks {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

@media only screen and (max-width: 1000px) {
  #background {
    flex-direction: column;
  }

  #placeholder {
    height: 45px;
  }

  .botlinks {
    flex-direction: column;
  }
}
</style>