<template>
  <div id="background">
    <div>
      <div id="title">Meet our team</div>
      <div style="height: 25px"></div>
      <div id="description">Hover on our profile pictures to learn more.</div>
      <div style="height: 45px"></div>
      <div id="pics">
        <div class="profileholder">
          <img
            class="pic"
            src="../../assets/viktor.webp"
            @mouseover="viktor = true"
            @mouseleave="viktor = false"
            alt
          />
          <transition name="fade" mode="out-in">
            <div class="profile" v-show="viktor">
              <p class="name">Viktor Shen</p>
              <div style="height: 5px"></div>
              <p class="studies">TUM - Master in Management & Technology</p>
              <div style="height: 5px"></div>
              <p class="job">💼 Product, Coding</p>
            </div>
          </transition>
        </div>
        <div class="profileholder">
          <img
            class="pic"
            src="../../assets/woojin.webp"
            @mouseover="woojin = true"
            @mouseleave="woojin = false"
            alt
          />
          <transition name="fade" mode="out-in">
            <div class="profile" v-show="woojin">
              <p class="name">Woojin Ko</p>
              <div style="height: 5px"></div>
              <p class="studies">TUM - Bachelor in Management & Technology</p>
              <div style="height: 5px"></div>
              <p class="job">💼 Operations, Coding</p>
            </div>
          </transition>
        </div>
        <div class="profileholder">
          <img
            class="pic"
            src="../../assets/veronica.webp"
            @mouseover="veronica = true"
            @mouseleave="veronica = false"
            alt
          />
          <transition name="fade" mode="out-in">
            <div class="profile" v-show="veronica">
              <p class="name">Veronica Chung</p>
              <div style="height: 5px"></div>
              <p class="studies">TUM - Bachelor in Management & Technology</p>
              <div style="height: 5px"></div>
              <p class="job">💼 Business Development</p>
            </div>
          </transition>
        </div>
        <div class="profileholder">
          <img
            class="pic"
            src="../../assets/jae.webp"
            @mouseover="jae = true"
            @mouseleave="jae = false"
            alt
          />
          <transition name="fade" mode="out-in">
            <div class="profile" v-show="jae">
              <p class="name">Jae Yoon Bae</p>
              <div style="height: 5px"></div>
              <p class="studies">TUM - Bachelor in Informatics</p>
              <div style="height: 5px"></div>
              <p class="job">💼 Coding</p>
            </div>
          </transition>
        </div>
        <div class="profileholder">
          <img
            class="pic"
            src="../../assets/hanlu.webp"
            @mouseover="hanlu = true"
            @mouseleave="hanlu = false"
            alt
          />
          <transition name="fade" mode="out-in">
            <div class="profile" v-show="hanlu">
              <p class="name">Hanlu Diao</p>
              <div style="height: 5px"></div>
              <p class="studies">TUM - Master in Management & Technology</p>
              <div style="height: 5px"></div>
              <p class="job">💼 Finance</p>
            </div>
          </transition>
        </div>
      </div>
      <div style="height: 45px"></div>
      <div style="height: 150px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeetOurTeam",
  components: {},
  data: () => {
    return {
      viktor: false,
      woojin: false,
      veronica: false,
      jae: false,
      hanlu: false,
    };
  },
};
</script>

<style scoped>
#background {
  padding-top: 120px;
  padding-bottom: 120px;
  background: transparent;
  align-items: center;
  padding-left: 105px;
  padding-right: 105px;
}

#title {
  color: #2b2a35;
  font-size: 60px;
  font-weight: 800;
  text-align: left;
  white-space: nowrap;
}

#description {
  color: #2b2a35;
  font-size: 20px;
  text-align: left;
  line-height: 150%;
}

#pics {
  display: flex;
  flex-wrap: wrap;
  margin-right: 60px;
}

.profileholder {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
}

.profile {
  margin-top: 170px;
  width: 220px;
  padding: 50px 20px;
  background: #2b2a35;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
}

.name {
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.studies {
  color: #707070;
  font-size: 15px;
  font-style: italic;
}

.job {
  color: white;
  font-size: 15px;
  font-weight: 500;
}

.pic {
  height: 150px;
  width: 150px;
  margin-right: 50px;
  margin-left: 0;
  margin-bottom: 50px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

@media only screen and (max-width: 1000px) {
  #background {
    padding-left: 20px;
    padding-right: 20px;
  }

  #title,
  #description {
    text-align: center;
  }
  #pics {
    justify-content: center;
    margin-right: 0;
  }
  .pic {
      margin-left: 50px;
  }
}

@media only screen and (max-width: 600px) {
  #title {
    font-size: 40px;
  }
}
</style>