<template>
  <div>
    <NavBar />
    <div style="height:50px"></div>
    <div data-custom-class="body">
      <div>
        <strong
          ><span style="font-size: 26px"
            ><span data-custom-class="title">PRIVACY NOTICE</span></span
          ></strong
        >
      </div>
      <div><br /></div>
      <div>
        <span style="color: rgb(127, 127, 127)">
          <strong>
            <span style="font-size: 15px">
              <span data-custom-class="subtitle">
                Last updated
                <bdt class="question">March 05, 2022</bdt>
              </span>
            </span>
          </strong>
        </span>
      </div>
      <div><br /></div>
      <div><br /></div>
      <div><br /></div>
      <div style="line-height: 1.5">
        <span style="color: rgb(127, 127, 127)">
          <span style="color: rgb(89, 89, 89); font-size: 15px">
            <span data-custom-class="body_text">
              This privacy notice for
              <bdt class="question">Yuni App</bdt>
              <span style="color: rgb(89, 89, 89)">
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
                </span>
              </span>
              ("
              <span style="color: rgb(89, 89, 89)">
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
                </span>
              </span>
              <strong>Company</strong>
            </span>
            <span style="color: rgb(127, 127, 127)">
              <span style="color: rgb(89, 89, 89)">
                <span data-custom-class="body_text">
                  <span style="color: rgb(89, 89, 89)">
                    <span data-custom-class="body_text">
                      <bdt class="statement-end-if-in-editor"
                        ><span data-custom-class="body_text"></span
                      ></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span data-custom-class="body_text"
              >," "<strong>we</strong>," "<strong>us</strong>," or
              "<strong>our</strong>"), describes how and why we might collect,
              store, use, and/or share ("<strong>process</strong>") your
              information when you use our services
              ("<strong>Services</strong>"), such as when you:</span
            >
          </span>
        </span>
        <span style="font-size: 15px">
          <span style="color: rgb(127, 127, 127)">
            <span data-custom-class="body_text">
              <span style="color: rgb(89, 89, 89)">
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)">
            <span style="font-size: 15px; color: rgb(89, 89, 89)">
              <span data-custom-class="body_text">
                Visit our website
                <bdt class="block-component"></bdt>
                at
                <bdt class="question"
                  ><a
                    href="https://yuni-app.com"
                    target="_blank"
                    data-custom-class="link"
                    >https://yuni-app.com</a
                  ></bdt
                >
                <span style="font-size: 15px">
                  <span style="color: rgb(89, 89, 89)">
                    <span data-custom-class="body_text">
                      <span style="font-size: 15px">
                        <span style="color: rgb(89, 89, 89)">
                          <bdt class="statement-end-if-in-editor"
                            >, or any website of ours that links to this privacy
                            notice</bdt
                          >
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div>
        <bdt class="block-component">
          <span style="font-size: 15px">
            <span style="font-size: 15px">
              <span style="color: rgb(127, 127, 127)">
                <span data-custom-class="body_text">
                  <span style="color: rgb(89, 89, 89)">
                    <span data-custom-class="body_text">
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </bdt>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span style="color: rgb(127, 127, 127)">
              <span data-custom-class="body_text">
                <span style="color: rgb(89, 89, 89)">
                  <span data-custom-class="body_text">
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)">
              <span style="font-size: 15px; color: rgb(89, 89, 89)">
                <span data-custom-class="body_text">
                  Engage with us in other related ways ― including any sales,
                  marketing, or events
                  <span style="font-size: 15px">
                    <span style="color: rgb(89, 89, 89)">
                      <span data-custom-class="body_text">
                        <span style="font-size: 15px">
                          <span style="color: rgb(89, 89, 89)">
                            <bdt class="statement-end-if-in-editor"></bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span style="color: rgb(127, 127, 127)">
              <span data-custom-class="body_text">
                <strong>Questions or concerns?&nbsp;</strong>Reading this
                privacy notice will help you understand your privacy rights and
                choices. If you do not agree with our policies and practices,
                please do not use our Services. If you still have any questions
                or concerns, please contact us at
                <bdt class="question">woojin.kid@gmail.com</bdt>
                .
              </span>
            </span>
          </span>
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <strong
            ><span style="font-size: 15px"
              ><span data-custom-class="heading_1"
                >SUMMARY OF KEY POINTS</span
              ></span
            ></strong
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong
                ><em
                  >This summary provides key points from our privacy notice, but
                  you can find out more details about any of these topics by
                  clicking the link following each key point or by using our
                  table of contents below to find the section you are looking
                  for. You can also click&nbsp;</em
                ></strong
              ></span
            ></span
          ><a data-custom-class="link" href="#toc"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong><em>here</em></strong></span
              ></span
            ></a
          ><span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong
                ><em>&nbsp;to go directly to our table of contents.</em></strong
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with
              <bdt class="block-component"></bdt>
              <bdt class="question">Yuni App</bdt>
              <bdt class="statement-end-if-in-editor"></bdt>
              and the Services, the choices you make, and the products and
              features you use. Click&nbsp;
            </span>
          </span>
          <a data-custom-class="link" href="#personalinfo"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          ><span style="font-size: 15px"
            ><span data-custom-class="body_text"
              >&nbsp;to learn more.</span
            ></span
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              <strong>Do we process any sensitive personal information?</strong>
              <bdt class="block-component"></bdt>
              We do not process sensitive personal information.
              <bdt class="else-block"></bdt>
            </span>
          </span>
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              <strong
                >Do you receive any information from third parties?</strong
              >
              <bdt class="block-component"></bdt>
              We do not receive any information from third parties.
              <bdt class="else-block"></bdt>
            </span>
          </span>
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong>How do you process my information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so. Click&nbsp;</span
            ></span
          ><a data-custom-class="link" href="#infouse"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          ><span style="font-size: 15px"
            ><span data-custom-class="body_text"
              >&nbsp;to learn more.</span
            ></span
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              <strong>
                In what situations and with which
                <bdt class="block-component"></bdt>
                parties do we share personal information?
              </strong>
              We may share information in specific situations and with specific
              <bdt class="block-component"></bdt>
              third parties. Click&nbsp;
            </span>
          </span>
          <a data-custom-class="link" href="#whoshare"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          >
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              &nbsp;to learn more.
              <bdt class="block-component"></bdt>
            </span>
          </span>
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong>What are your rights?</strong> Depending on where you are
              located geographically, the applicable privacy law may mean you
              have certain rights regarding your personal information.
              Click&nbsp;</span
            ></span
          ><a data-custom-class="link" href="#privacyrights"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          ><span style="font-size: 15px"
            ><span data-custom-class="body_text"
              >&nbsp;to learn more.</span
            ></span
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              <strong>How do I exercise my rights?</strong> The easiest way to
              exercise your rights is by filling out our data subject request
              form available
              <bdt class="block-component"></bdt>
            </span>
          </span>
          <a
            data-custom-class="link"
            href="https://app.termly.io/notify/3f5abc02-55c6-4d13-a08f-9baa84bbe369"
            rel="noopener noreferrer"
            target="_blank"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          >
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              <bdt class="block-component"></bdt>
              , or by contacting us. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </span>
          </span>
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              Want to learn more about what
              <bdt class="block-component"></bdt>
              <bdt class="question">Yuni App</bdt>
              <bdt class="statement-end-if-in-editor"></bdt>
              does with any information we collect? Click&nbsp;
            </span>
          </span>
          <a data-custom-class="link" href="#toc"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          ><span style="font-size: 15px"
            ><span data-custom-class="body_text"
              >&nbsp;to review the notice in full.</span
            ></span
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5"><br /></div>
        <div id="toc" style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(0, 0, 0)"
                ><strong
                  ><span data-custom-class="heading_1"
                    >TABLE OF CONTENTS</span
                  ></strong
                ></span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#infocollect"
              ><span style="color: rgb(89, 89, 89)"
                >1. WHAT INFORMATION DO WE COLLECT?</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <a data-custom-class="link" href="#infouse">
              <span style="color: rgb(89, 89, 89)">
                2. HOW DO WE PROCESS YOUR INFORMATION?
                <bdt class="block-component"></bdt>
              </span>
            </a>
          </span>
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <a data-custom-class="link" href="#legalbases">
              <span style="color: rgb(89, 89, 89)">
                3.
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    >WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                    INFORMATION?</span
                  ></span
                >
                <bdt class="statement-end-if-in-editor"></bdt>
              </span>
            </a>
          </span>
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span style="color: rgb(89, 89, 89)"
              ><a data-custom-class="link" href="#whoshare"
                >4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a
              ></span
            >
            <span data-custom-class="body_text">
              <bdt class="block-component"></bdt>
            </span>
            <span style="color: rgb(127, 127, 127)">
              <span style="color: rgb(89, 89, 89)">
                <span data-custom-class="body_text">
                  <span style="color: rgb(89, 89, 89)">
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span style="color: rgb(89, 89, 89)">
                    <span style="color: rgb(89, 89, 89)">
                      <span style="color: rgb(89, 89, 89)">
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <a data-custom-class="link" href="#inforetain"
              ><span style="color: rgb(89, 89, 89)"
                >5. HOW LONG DO WE KEEP YOUR INFORMATION?</span
              ></a
            >
            <span style="color: rgb(127, 127, 127)">
              <span style="color: rgb(89, 89, 89)">
                <span data-custom-class="body_text">
                  <span style="color: rgb(89, 89, 89)">
                    <span style="color: rgb(89, 89, 89)">
                      <bdt class="block-component"></bdt>
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <a data-custom-class="link" href="#infominors"
              ><span style="color: rgb(89, 89, 89)"
                >6. DO WE COLLECT INFORMATION FROM MINORS?</span
              ></a
            >
            <span style="color: rgb(127, 127, 127)">
              <span style="color: rgb(89, 89, 89)">
                <span data-custom-class="body_text">
                  <span style="color: rgb(89, 89, 89)">
                    <bdt class="statement-end-if-in-editor"></bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span style="color: rgb(89, 89, 89)"
              ><a data-custom-class="link" href="#privacyrights"
                >7. WHAT ARE YOUR PRIVACY RIGHTS?</a
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#DNT"
              ><span style="color: rgb(89, 89, 89)"
                >8. CONTROLS FOR DO-NOT-TRACK FEATURES</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#caresidents"
              ><span style="color: rgb(89, 89, 89)"
                >9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#policyupdates"
              ><span style="color: rgb(89, 89, 89)"
                >10. DO WE MAKE UPDATES TO THIS NOTICE?</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <a data-custom-class="link" href="#contact"
            ><span style="color: rgb(89, 89, 89); font-size: 15px"
              >11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span
            ></a
          >
        </div>
        <div style="line-height: 1.5">
          <a data-custom-class="link" href="#request"
            ><span style="color: rgb(89, 89, 89)"
              >12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
              FROM YOU?</span
            ></a
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div id="infocollect" style="line-height: 1.5">
          <span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89); font-size: 15px"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span id="control" style="color: rgb(0, 0, 0)"
                    ><strong
                      ><span data-custom-class="heading_1"
                        >1. WHAT INFORMATION DO WE COLLECT?</span
                      ></strong
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div id="personalinfo" style="line-height: 1.5">
          <span data-custom-class="heading_2" style="color: rgb(0, 0, 0)"
            ><span style="font-size: 15px"
              ><strong>Personal information you disclose to us</strong></span
            ></span
          >
        </div>
        <div>
          <div><br /></div>
          <div style="line-height: 1.5">
            <span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><strong><em>In Short:</em></strong></span
                      ></span
                    ></span
                  ></span
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><strong><em>&nbsp;</em></strong
                        ><em
                          >We collect personal information that you provide to
                          us.</em
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)">
            <span style="font-size: 15px; color: rgb(89, 89, 89)">
              <span data-custom-class="body_text">
                We collect personal information that you voluntarily provide to
                us when you
                <span style="font-size: 15px">
                  <bdt class="block-component"></bdt>
                </span>
              </span>
              <span data-custom-class="body_text"
                >express an interest in obtaining information about us or our
                products and Services, when you participate in activities on the
                Services, or otherwise when you contact us.</span
              >
            </span>
          </span>
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)">
            <span style="font-size: 15px; color: rgb(89, 89, 89)">
              <span data-custom-class="body_text">
                <span style="font-size: 15px">
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div id="sensitiveinfo" style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              <strong>Sensitive Information.</strong>
              <bdt class="block-component"></bdt>
              We do not process sensitive information.
            </span>
          </span>
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              <bdt class="else-block"></bdt>
            </span>
          </span>
          <span style="font-size: 15px; color: rgb(89, 89, 89)">
            <span style="font-size: 15px; color: rgb(89, 89, 89)">
              <span data-custom-class="body_text">
                <span style="font-size: 15px">
                  <span data-custom-class="body_text">
                    <bdt class="block-component">
                      <bdt class="block-component"></bdt>
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
            <bdt class="block-component"></bdt>
          </span>
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                >All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.</span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)">
            <span style="font-size: 15px; color: rgb(89, 89, 89)">
              <span data-custom-class="body_text">
                <bdt class="block-component"></bdt>
              </span>
              <span data-custom-class="body_text">
                <span style="color: rgb(89, 89, 89); font-size: 15px">
                  <span data-custom-class="body_text">
                    <span style="color: rgb(89, 89, 89); font-size: 15px">
                      <span data-custom-class="body_text">
                        <bdt class="statement-end-if-in-editor">
                          <bdt class="block-component"></bdt>
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              <bdt class="block-component"></bdt>
            </span>
          </span>
        </div>
        <div id="infouse" style="line-height: 1.5">
          <span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89); font-size: 15px"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span id="control" style="color: rgb(0, 0, 0)"
                    ><strong
                      ><span data-custom-class="heading_1"
                        >2. HOW DO WE PROCESS YOUR INFORMATION?</span
                      ></strong
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div>
          <div style="line-height: 1.5"><br /></div>
          <div style="line-height: 1.5">
            <span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><strong><em>In Short:&nbsp;</em></strong
                        ><em
                          >We process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other
                          purposes with your consent.</em
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)">
            <span style="font-size: 15px; color: rgb(89, 89, 89)">
              <span data-custom-class="body_text">
                <strong
                  >We process your personal information for a variety of
                  reasons, depending on how you interact with our Services,
                  including:</strong
                >
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </span>
          <div style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)">
              <span style="font-size: 15px; color: rgb(89, 89, 89)">
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
            <div style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)">
                <span style="font-size: 15px; color: rgb(89, 89, 89)">
                  <span data-custom-class="body_text">
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
              <div style="line-height: 1.5">
                <span style="font-size: 15px; color: rgb(89, 89, 89)">
                  <span style="font-size: 15px; color: rgb(89, 89, 89)">
                    <span data-custom-class="body_text">
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                </span>
                <div style="line-height: 1.5">
                  <span style="font-size: 15px; color: rgb(89, 89, 89)">
                    <span style="font-size: 15px; color: rgb(89, 89, 89)">
                      <span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>
                      </span>
                    </span>
                  </span>
                  <div style="line-height: 1.5">
                    <bdt class="block-component"
                      ><span style="font-size: 15px"></span
                    ></bdt>
                    <div style="line-height: 1.5">
                      <bdt class="block-component"
                        ><span style="font-size: 15px"></span
                      ></bdt>
                      <div style="line-height: 1.5">
                        <bdt class="block-component"
                          ><span style="font-size: 15px"></span
                        ></bdt>
                        <div style="line-height: 1.5">
                          <bdt class="block-component"
                            ><span style="font-size: 15px"
                              ><span data-custom-class="body_text"></span></span
                          ></bdt>
                          <p style="font-size: 15px; line-height: 1.5">
                            <bdt class="block-component"
                              ><span style="font-size: 15px"></span
                            ></bdt>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5">
                            <bdt class="block-component"
                              ><span style="font-size: 15px"></span
                            ></bdt>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5">
                            <bdt class="block-component"></bdt>
                          </p>
                          <p style="font-size: 15px; line-height: 1.5">
                            <bdt class="block-component"></bdt>
                          </p>
                          <div style="line-height: 1.5">
                            <bdt class="block-component"
                              ><span style="font-size: 15px"></span
                            ></bdt>
                            <div style="line-height: 1.5">
                              <bdt class="block-component"
                                ><span style="font-size: 15px"></span
                              ></bdt>
                              <div style="line-height: 1.5">
                                <bdt class="block-component"
                                  ><span style="font-size: 15px"></span
                                ></bdt>
                                <div style="line-height: 1.5">
                                  <span style="font-size: 15px">
                                    <bdt class="block-component"
                                      ><span
                                        data-custom-class="body_text"
                                      ></span
                                    ></bdt>
                                  </span>
                                  <div style="line-height: 1.5">
                                    <bdt class="block-component"
                                      ><span style="font-size: 15px"
                                        ><span
                                          data-custom-class="body_text"
                                        ></span></span
                                    ></bdt>
                                    <div style="line-height: 1.5">
                                      <bdt class="block-component"
                                        ><span style="font-size: 15px"
                                          ><span
                                            data-custom-class="body_text"
                                          ></span></span
                                      ></bdt>
                                      <div style="line-height: 1.5">
                                        <bdt class="block-component"
                                          ><span style="font-size: 15px"
                                            ><span
                                              data-custom-class="body_text"
                                            ></span></span
                                        ></bdt>
                                        <div style="line-height: 1.5">
                                          <bdt class="block-component"
                                            ><span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                              ></span></span
                                          ></bdt>
                                          <div style="line-height: 1.5">
                                            <bdt class="block-component"
                                              ><span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                ></span></span
                                            ></bdt>
                                            <div style="line-height: 1.5">
                                              <bdt class="block-component"
                                                ><span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                  ></span></span
                                              ></bdt>
                                              <div style="line-height: 1.5">
                                                <bdt class="block-component"
                                                  ><span style="font-size: 15px"
                                                    ><span
                                                      data-custom-class="body_text"
                                                    ></span></span
                                                ></bdt>
                                                <div style="line-height: 1.5">
                                                  <bdt class="block-component"
                                                    ><span
                                                      style="font-size: 15px"
                                                      ><span
                                                        data-custom-class="body_text"
                                                      ></span></span
                                                  ></bdt>
                                                  <div style="line-height: 1.5">
                                                    <bdt class="block-component"
                                                      ><span
                                                        style="font-size: 15px"
                                                        ><span
                                                          data-custom-class="body_text"
                                                        ></span></span
                                                    ></bdt>
                                                    <div
                                                      style="line-height: 1.5"
                                                    >
                                                      <bdt
                                                        class="block-component"
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                          ><span
                                                            data-custom-class="body_text"
                                                          ></span></span
                                                      ></bdt>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <bdt
                                                          class="
                                                            block-component
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                            ></span></span
                                                        ></bdt>
                                                      </div>
                                                      <ul>
                                                        <li
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            data-custom-class="body_text"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><strong
                                                                >To save or
                                                                protect an
                                                                individual's
                                                                vital
                                                                interest.</strong
                                                              >
                                                              We may process
                                                              your information
                                                              when necessary to
                                                              save or protect an
                                                              individual’s vital
                                                              interest, such as
                                                              to prevent
                                                              harm.</span
                                                            ></span
                                                          >
                                                          <bdt
                                                            class="
                                                              statement-end-if-in-editor
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span
                                                          ></bdt>
                                                        </li>
                                                      </ul>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <bdt
                                                          class="
                                                            block-component
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                            ></span></span
                                                        ></bdt>
                                                        <bdt
                                                          class="
                                                            block-component
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                            ></span></span
                                                        ></bdt>
                                                        <bdt
                                                          class="
                                                            block-component
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                            ></span></span
                                                        ></bdt>
                                                        <bdt
                                                          class="
                                                            block-component
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                            ></span></span
                                                        ></bdt>
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        id="legalbases"
                                                        style="line-height: 1.5"
                                                      >
                                                        <strong
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="heading_1"
                                                              >3. WHAT LEGAL
                                                              BASES DO WE RELY
                                                              ON TO PROCESS YOUR
                                                              INFORMATION?</span
                                                            ></span
                                                          ></strong
                                                        >
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <em
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              ><strong
                                                                >In
                                                                Short:&nbsp;</strong
                                                              >We only process
                                                              your personal
                                                              information when
                                                              we believe it is
                                                              necessary and we
                                                              have a valid legal
                                                              reason (i.e.,
                                                              legal basis) to do
                                                              so under
                                                              applicable law,
                                                              like with your
                                                              consent, to comply
                                                              with laws, to
                                                              provide you with
                                                              services to enter
                                                              into or fulfill
                                                              our contractual
                                                              obligations, to
                                                              protect your
                                                              rights, or to
                                                              fulfill our
                                                              legitimate
                                                              business
                                                              interests.</span
                                                            ></span
                                                          ></em
                                                        >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                        >
                                                          <span
                                                            data-custom-class="body_text"
                                                          >
                                                            <bdt
                                                              class="
                                                                block-component
                                                              "
                                                            ></bdt>
                                                          </span>
                                                          <span
                                                            data-custom-class="body_text"
                                                          >
                                                            <bdt
                                                              class="
                                                                block-component
                                                              "
                                                            ></bdt>
                                                          </span>
                                                        </span>
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <br />
                                                      </div>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                          ><span
                                                            data-custom-class="body_text"
                                                            >The General Data
                                                            Protection
                                                            Regulation (GDPR)
                                                            and UK GDPR require
                                                            us to explain the
                                                            valid legal bases we
                                                            rely on in order to
                                                            process your
                                                            personal
                                                            information. As
                                                            such, we may rely on
                                                            the following legal
                                                            bases to process
                                                            your personal
                                                            information:</span
                                                          ></span
                                                        >
                                                      </div>
                                                      <ul>
                                                        <li
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              ><strong
                                                                >Consent.&nbsp;</strong
                                                              >We may process
                                                              your information
                                                              if you have given
                                                              us permission
                                                              (i.e., consent) to
                                                              use your personal
                                                              information for a
                                                              specific purpose.
                                                              You can withdraw
                                                              your consent at
                                                              any time.
                                                              Click&nbsp;</span
                                                            ></span
                                                          ><a
                                                            data-custom-class="link"
                                                            href="#withdrawconsent"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                >here</span
                                                              ></span
                                                            ></a
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              >&nbsp;to learn
                                                              more.</span
                                                            ></span
                                                          >
                                                        </li>
                                                      </ul>
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <bdt
                                                          class="
                                                            block-component
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                            ></span></span
                                                        ></bdt>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <bdt
                                                            class="
                                                              block-component
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span
                                                          ></bdt>
                                                          <bdt
                                                            class="
                                                              block-component
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span
                                                          ></bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              data-custom-class="body_text"
                                                            >
                                                              <span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                              >
                                                                <strong
                                                                  >Legal
                                                                  Obligations.</strong
                                                                >
                                                                We may process
                                                                your information
                                                                where we believe
                                                                it is necessary
                                                                for compliance
                                                                with our legal
                                                                obligations,
                                                                such as to
                                                                cooperate with a
                                                                law enforcement
                                                                body or
                                                                regulatory
                                                                agency, exercise
                                                                or defend our
                                                                legal rights, or
                                                                disclose your
                                                                information as
                                                                evidence in
                                                                litigation in
                                                                which we are
                                                                involved.
                                                                <bdt
                                                                  class="
                                                                    statement-end-if-in-editor
                                                                  "
                                                                ></bdt>
                                                                <br />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <bdt
                                                            class="
                                                              block-component
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span
                                                          ></bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              data-custom-class="body_text"
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                                ><strong
                                                                  >Vital
                                                                  Interests.</strong
                                                                >
                                                                We may process
                                                                your information
                                                                where we believe
                                                                it is necessary
                                                                to protect your
                                                                vital interests
                                                                or the vital
                                                                interests of a
                                                                third party,
                                                                such as
                                                                situations
                                                                involving
                                                                potential
                                                                threats to the
                                                                safety of any
                                                                person.</span
                                                              ></span
                                                            >
                                                            <bdt
                                                              class="
                                                                statement-end-if-in-editor
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                                ><span
                                                                  data-custom-class="body_text"
                                                                ></span></span
                                                            ></bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <bdt
                                                            class="
                                                              block-component
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span
                                                          ></bdt>
                                                          <bdt
                                                            class="
                                                              block-component
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span
                                                          ></bdt>
                                                          <bdt
                                                            class="
                                                              statement-end-if-in-editor
                                                            "
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                              ></span></span
                                                          ></bdt>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="whoshare"
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              color: rgb(
                                                                127,
                                                                127,
                                                                127
                                                              );
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    id="control"
                                                                    style="
                                                                      color: rgb(
                                                                        0,
                                                                        0,
                                                                        0
                                                                      );
                                                                    "
                                                                    ><strong
                                                                      ><span
                                                                        data-custom-class="heading_1"
                                                                        >4. WHEN
                                                                        AND WITH
                                                                        WHOM DO
                                                                        WE SHARE
                                                                        YOUR
                                                                        PERSONAL
                                                                        INFORMATION?</span
                                                                      ></strong
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          >
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                            >
                                                              <span
                                                                data-custom-class="body_text"
                                                              >
                                                                <strong
                                                                  ><em
                                                                    >In
                                                                    Short:</em
                                                                  ></strong
                                                                >
                                                                <em>
                                                                  &nbsp;We may
                                                                  share
                                                                  information in
                                                                  specific
                                                                  situations
                                                                  described in
                                                                  this section
                                                                  and/or with
                                                                  the following
                                                                  <bdt
                                                                    class="
                                                                      block-component
                                                                    "
                                                                  ></bdt>
                                                                  third parties.
                                                                </em>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                            >
                                                              <span
                                                                data-custom-class="body_text"
                                                              >
                                                                <bdt
                                                                  class="
                                                                    block-component
                                                                  "
                                                                ></bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                          >
                                                            <span
                                                              data-custom-class="body_text"
                                                            >
                                                              We
                                                              <bdt
                                                                class="
                                                                  block-component
                                                                "
                                                              ></bdt>
                                                              may need to share
                                                              your personal
                                                              information in the
                                                              following
                                                              situations:
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><span
                                                                data-custom-class="body_text"
                                                                ><strong
                                                                  >Business
                                                                  Transfers.</strong
                                                                >
                                                                We may share or
                                                                transfer your
                                                                information in
                                                                connection with,
                                                                or during
                                                                negotiations of,
                                                                any merger, sale
                                                                of company
                                                                assets,
                                                                financing, or
                                                                acquisition of
                                                                all or a portion
                                                                of our business
                                                                to another
                                                                company.</span
                                                              ></span
                                                            >
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                          >
                                                            <span
                                                              data-custom-class="body_text"
                                                            >
                                                              <bdt
                                                                class="
                                                                  block-component
                                                                "
                                                              ></bdt>
                                                            </span>
                                                          </span>
                                                          <div
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                            >
                                                              <bdt
                                                                class="
                                                                  block-component
                                                                "
                                                                ><span
                                                                  data-custom-class="body_text"
                                                                ></span
                                                              ></bdt>
                                                            </span>
                                                            <div
                                                              style="
                                                                line-height: 1.5;
                                                              "
                                                            >
                                                              <bdt
                                                                class="
                                                                  block-component
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                  ></span></span
                                                              ></bdt>
                                                              <div
                                                                style="
                                                                  line-height: 1.5;
                                                                "
                                                              >
                                                                <bdt
                                                                  class="
                                                                    block-component
                                                                  "
                                                                  ><span
                                                                    style="
                                                                      font-size: 15px;
                                                                    "
                                                                    ><span
                                                                      data-custom-class="body_text"
                                                                    ></span></span
                                                                ></bdt>
                                                                <div
                                                                  style="
                                                                    line-height: 1.5;
                                                                  "
                                                                >
                                                                  <bdt
                                                                    class="
                                                                      block-component
                                                                    "
                                                                    ><span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                      ><span
                                                                        data-custom-class="body_text"
                                                                      ></span></span
                                                                  ></bdt>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <bdt
                                                                      class="
                                                                        block-component
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                        ></span
                                                                      ></span>
                                                                    </bdt>
                                                                    <span
                                                                      style="
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                        font-size: 15px;
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                        "
                                                                      >
                                                                        <span
                                                                          style="
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                        >
                                                                          <span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                            >
                                                                              <bdt
                                                                                class="
                                                                                  block-component
                                                                                "
                                                                                ><span
                                                                                  data-custom-class="heading_1"
                                                                                ></span>
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <span
                                                                      data-custom-class="body_text"
                                                                    >
                                                                      <span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                          font-size: 15px;
                                                                        "
                                                                      >
                                                                        <span
                                                                          style="
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                            font-size: 15px;
                                                                          "
                                                                        >
                                                                          <span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              style="
                                                                                font-size: 15px;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: rgb(
                                                                                    89,
                                                                                    89,
                                                                                    89
                                                                                  );
                                                                                "
                                                                              >
                                                                                <span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                >
                                                                                  <span
                                                                                    style="
                                                                                      color: rgb(
                                                                                        89,
                                                                                        89,
                                                                                        89
                                                                                      );
                                                                                    "
                                                                                  >
                                                                                    <span
                                                                                      data-custom-class="body_text"
                                                                                    >
                                                                                      <bdt
                                                                                        class="
                                                                                          block-component
                                                                                        "
                                                                                      ></bdt>
                                                                                    </span>
                                                                                    <bdt
                                                                                      class="
                                                                                        block-component
                                                                                      "
                                                                                    >
                                                                                      <span
                                                                                        data-custom-class="body_text"
                                                                                      >
                                                                                        <bdt
                                                                                          class="
                                                                                            block-component
                                                                                          "
                                                                                        >
                                                                                        </bdt>
                                                                                      </span>
                                                                                    </bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="inforetain"
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        color: rgb(
                                                                          127,
                                                                          127,
                                                                          127
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              id="control"
                                                                              style="
                                                                                color: rgb(
                                                                                  0,
                                                                                  0,
                                                                                  0
                                                                                );
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  data-custom-class="heading_1"
                                                                                  >5.
                                                                                  HOW
                                                                                  LONG
                                                                                  DO
                                                                                  WE
                                                                                  KEEP
                                                                                  YOUR
                                                                                  INFORMATION?</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          ><strong
                                                                            ><em
                                                                              >In
                                                                              Short:&nbsp;</em
                                                                            ></strong
                                                                          ><em
                                                                            >We
                                                                            keep
                                                                            your
                                                                            information
                                                                            for
                                                                            as
                                                                            long
                                                                            as
                                                                            necessary
                                                                            to
                                                                            fulfill
                                                                            the
                                                                            purposes
                                                                            outlined
                                                                            in
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            unless
                                                                            otherwise
                                                                            required
                                                                            by
                                                                            law.</em
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          We
                                                                          will
                                                                          only
                                                                          keep
                                                                          your
                                                                          personal
                                                                          information
                                                                          for as
                                                                          long
                                                                          as it
                                                                          is
                                                                          necessary
                                                                          for
                                                                          the
                                                                          purposes
                                                                          set
                                                                          out in
                                                                          this
                                                                          privacy
                                                                          notice,
                                                                          unless
                                                                          a
                                                                          longer
                                                                          retention
                                                                          period
                                                                          is
                                                                          required
                                                                          or
                                                                          permitted
                                                                          by law
                                                                          (such
                                                                          as
                                                                          tax,
                                                                          accounting,
                                                                          or
                                                                          other
                                                                          legal
                                                                          requirements).
                                                                          No
                                                                          purpose
                                                                          in
                                                                          this
                                                                          notice
                                                                          will
                                                                          require
                                                                          us
                                                                          keeping
                                                                          your
                                                                          personal
                                                                          information
                                                                          for
                                                                          longer
                                                                          than
                                                                          <span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                            >
                                                                              <span
                                                                                data-custom-class="body_text"
                                                                              >
                                                                                <bdt
                                                                                  class="
                                                                                    block-component
                                                                                  "
                                                                                ></bdt>
                                                                                <bdt
                                                                                  class="
                                                                                    question
                                                                                  "
                                                                                  >6
                                                                                  months</bdt
                                                                                >
                                                                                <bdt
                                                                                  class="
                                                                                    statement-end-if-in-editor
                                                                                  "
                                                                                >
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          When
                                                                          we
                                                                          have
                                                                          no
                                                                          ongoing
                                                                          legitimate
                                                                          business
                                                                          need
                                                                          to
                                                                          process
                                                                          your
                                                                          personal
                                                                          information,
                                                                          we
                                                                          will
                                                                          either
                                                                          delete
                                                                          or
                                                                          anonymize
                                                                          such
                                                                          information,
                                                                          or, if
                                                                          this
                                                                          is not
                                                                          possible
                                                                          (for
                                                                          example,
                                                                          because
                                                                          your
                                                                          personal
                                                                          information
                                                                          has
                                                                          been
                                                                          stored
                                                                          in
                                                                          backup
                                                                          archives),
                                                                          then
                                                                          we
                                                                          will
                                                                          securely
                                                                          store
                                                                          your
                                                                          personal
                                                                          information
                                                                          and
                                                                          isolate
                                                                          it
                                                                          from
                                                                          any
                                                                          further
                                                                          processing
                                                                          until
                                                                          deletion
                                                                          is
                                                                          possible.
                                                                          <span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                          >
                                                                            <bdt
                                                                              class="
                                                                                block-component
                                                                              "
                                                                            ></bdt>
                                                                          </span>
                                                                          <span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              data-custom-class="body_text"
                                                                            >
                                                                              <bdt
                                                                                class="
                                                                                  block-component
                                                                                "
                                                                              ></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="infominors"
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        color: rgb(
                                                                          127,
                                                                          127,
                                                                          127
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              id="control"
                                                                              style="
                                                                                color: rgb(
                                                                                  0,
                                                                                  0,
                                                                                  0
                                                                                );
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  data-custom-class="heading_1"
                                                                                  >6.
                                                                                  DO
                                                                                  WE
                                                                                  COLLECT
                                                                                  INFORMATION
                                                                                  FROM
                                                                                  MINORS?</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          ><strong
                                                                            ><em
                                                                              >In
                                                                              Short:</em
                                                                            ></strong
                                                                          ><em
                                                                            >&nbsp;We
                                                                            do
                                                                            not
                                                                            knowingly
                                                                            collect
                                                                            data
                                                                            from
                                                                            or
                                                                            market
                                                                            to
                                                                            children
                                                                            under
                                                                            18
                                                                            years
                                                                            of
                                                                            age.</em
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          We do
                                                                          not
                                                                          knowingly
                                                                          solicit
                                                                          data
                                                                          from
                                                                          or
                                                                          market
                                                                          to
                                                                          children
                                                                          under
                                                                          18
                                                                          years
                                                                          of
                                                                          age.
                                                                          By
                                                                          using
                                                                          the
                                                                          Services,
                                                                          you
                                                                          represent
                                                                          that
                                                                          you
                                                                          are at
                                                                          least
                                                                          18 or
                                                                          that
                                                                          you
                                                                          are
                                                                          the
                                                                          parent
                                                                          or
                                                                          guardian
                                                                          of
                                                                          such a
                                                                          minor
                                                                          and
                                                                          consent
                                                                          to
                                                                          such
                                                                          minor
                                                                          dependent’s
                                                                          use of
                                                                          the
                                                                          Services.
                                                                          If we
                                                                          learn
                                                                          that
                                                                          personal
                                                                          information
                                                                          from
                                                                          users
                                                                          less
                                                                          than
                                                                          18
                                                                          years
                                                                          of age
                                                                          has
                                                                          been
                                                                          collected,
                                                                          we
                                                                          will
                                                                          deactivate
                                                                          the
                                                                          account
                                                                          and
                                                                          take
                                                                          reasonable
                                                                          measures
                                                                          to
                                                                          promptly
                                                                          delete
                                                                          such
                                                                          data
                                                                          from
                                                                          our
                                                                          records.
                                                                          If you
                                                                          become
                                                                          aware
                                                                          of any
                                                                          data
                                                                          we may
                                                                          have
                                                                          collected
                                                                          from
                                                                          children
                                                                          under
                                                                          age
                                                                          18,
                                                                          please
                                                                          contact
                                                                          us at
                                                                          <span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              data-custom-class="body_text"
                                                                            >
                                                                              <bdt
                                                                                class="
                                                                                  block-component
                                                                                "
                                                                              ></bdt>
                                                                              <bdt
                                                                                class="
                                                                                  question
                                                                                "
                                                                                >woojin.kid@gmail.com
                                                                              </bdt>
                                                                              <bdt
                                                                                class="
                                                                                  else-block
                                                                                "
                                                                              ></bdt>
                                                                            </span>
                                                                          </span>
                                                                          .
                                                                          <span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                                font-size: 15px;
                                                                              "
                                                                            >
                                                                              <span
                                                                                data-custom-class="body_text"
                                                                              >
                                                                                <bdt
                                                                                  class="
                                                                                    statement-end-if-in-editor
                                                                                  "
                                                                                >
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="privacyrights"
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        color: rgb(
                                                                          127,
                                                                          127,
                                                                          127
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              id="control"
                                                                              style="
                                                                                color: rgb(
                                                                                  0,
                                                                                  0,
                                                                                  0
                                                                                );
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  data-custom-class="heading_1"
                                                                                  >7.
                                                                                  WHAT
                                                                                  ARE
                                                                                  YOUR
                                                                                  PRIVACY
                                                                                  RIGHTS?</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          <strong
                                                                            ><em
                                                                              >In
                                                                              Short:</em
                                                                            ></strong
                                                                          >
                                                                          <em>
                                                                            &nbsp;
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                "
                                                                              >
                                                                                <span
                                                                                  data-custom-class="body_text"
                                                                                >
                                                                                  <em>
                                                                                    <bdt
                                                                                      class="
                                                                                        block-component
                                                                                      "
                                                                                    ></bdt>
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            In
                                                                            some
                                                                            regions,
                                                                            such
                                                                            as
                                                                            <bdt
                                                                              class="
                                                                                block-component
                                                                              "
                                                                            ></bdt>
                                                                            the
                                                                            European
                                                                            Economic
                                                                            Area
                                                                            (EEA)
                                                                            and
                                                                            United
                                                                            Kingdom
                                                                            (UK)
                                                                            <bdt
                                                                              class="
                                                                                block-component
                                                                              "
                                                                            ></bdt>
                                                                            ,
                                                                            you
                                                                            have
                                                                            rights
                                                                            that
                                                                            allow
                                                                            you
                                                                            greater
                                                                            access
                                                                            to
                                                                            and
                                                                            control
                                                                            over
                                                                            your
                                                                            personal
                                                                            information.
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                "
                                                                              >
                                                                                <span
                                                                                  data-custom-class="body_text"
                                                                                >
                                                                                  <em>
                                                                                    <bdt
                                                                                      class="
                                                                                        statement-end-if-in-editor
                                                                                      "
                                                                                    >
                                                                                    </bdt>
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                              &nbsp;
                                                                            </span>
                                                                            You
                                                                            may
                                                                            review,
                                                                            change,
                                                                            or
                                                                            terminate
                                                                            your
                                                                            account
                                                                            at
                                                                            any
                                                                            time.
                                                                          </em>
                                                                          <span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                          >
                                                                            <span
                                                                              style="
                                                                                font-size: 15px;
                                                                              "
                                                                            >
                                                                              <bdt
                                                                                class="
                                                                                  block-component
                                                                                "
                                                                              ></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          In
                                                                          some
                                                                          regions
                                                                          (like
                                                                          <bdt
                                                                            class="
                                                                              block-component
                                                                            "
                                                                          ></bdt>
                                                                          the
                                                                          EEA
                                                                          and UK
                                                                          <bdt
                                                                            class="
                                                                              block-component
                                                                            "
                                                                          ></bdt>
                                                                          ), you
                                                                          have
                                                                          certain
                                                                          rights
                                                                          under
                                                                          applicable
                                                                          data
                                                                          protection
                                                                          laws.
                                                                          These
                                                                          may
                                                                          include
                                                                          the
                                                                          right
                                                                          (i) to
                                                                          request
                                                                          access
                                                                          and
                                                                          obtain
                                                                          a copy
                                                                          of
                                                                          your
                                                                          personal
                                                                          information,
                                                                          (ii)
                                                                          to
                                                                          request
                                                                          rectification
                                                                          or
                                                                          erasure;
                                                                          (iii)
                                                                          to
                                                                          restrict
                                                                          the
                                                                          processing
                                                                          of
                                                                          your
                                                                          personal
                                                                          information;
                                                                          and
                                                                          (iv)
                                                                          if
                                                                          applicable,
                                                                          to
                                                                          data
                                                                          portability.
                                                                          In
                                                                          certain
                                                                          circumstances,
                                                                          you
                                                                          may
                                                                          also
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          object
                                                                          to the
                                                                          processing
                                                                          of
                                                                          your
                                                                          personal
                                                                          information.
                                                                          You
                                                                          can
                                                                          make
                                                                          such a
                                                                          request
                                                                          by
                                                                          contacting
                                                                          us by
                                                                          using
                                                                          the
                                                                          contact
                                                                          details
                                                                          provided
                                                                          in the
                                                                          section
                                                                          “
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <a
                                                                      data-custom-class="link"
                                                                      href="#contact"
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            data-custom-class="body_text"
                                                                            >HOW
                                                                            CAN
                                                                            YOU
                                                                            CONTACT
                                                                            US
                                                                            ABOUT
                                                                            THIS
                                                                            NOTICE?</span
                                                                          ></span
                                                                        ></span
                                                                      ></a
                                                                    ><span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          >”
                                                                          below.</span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                          >We
                                                                          will
                                                                          consider
                                                                          and
                                                                          act
                                                                          upon
                                                                          any
                                                                          request
                                                                          in
                                                                          accordance
                                                                          with
                                                                          applicable
                                                                          data
                                                                          protection
                                                                          laws.</span
                                                                        >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          <span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                            >
                                                                              <span
                                                                                data-custom-class="body_text"
                                                                              >
                                                                                <span
                                                                                  style="
                                                                                    color: rgb(
                                                                                      89,
                                                                                      89,
                                                                                      89
                                                                                    );
                                                                                  "
                                                                                >
                                                                                  <span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                  >
                                                                                    <bdt
                                                                                      class="
                                                                                        statement-end-if-in-editor
                                                                                      "
                                                                                    >
                                                                                    </bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      >&nbsp;</span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          >If
                                                                          you
                                                                          are
                                                                          located
                                                                          in the
                                                                          EEA or
                                                                          UK and
                                                                          you
                                                                          believe
                                                                          we are
                                                                          unlawfully
                                                                          processing
                                                                          your
                                                                          personal
                                                                          information,
                                                                          you
                                                                          also
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          complain
                                                                          to
                                                                          your
                                                                          local
                                                                          data
                                                                          protection
                                                                          supervisory
                                                                          authority.
                                                                          You
                                                                          can
                                                                          find
                                                                          their
                                                                          contact
                                                                          details
                                                                          here:
                                                                          <span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                              ><span
                                                                                data-custom-class="body_text"
                                                                                ><span
                                                                                  style="
                                                                                    color: rgb(
                                                                                      48,
                                                                                      48,
                                                                                      241
                                                                                    );
                                                                                  "
                                                                                  ><span
                                                                                    data-custom-class="body_text"
                                                                                    ><a
                                                                                      data-custom-class="link"
                                                                                      href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                      rel="noopener noreferrer"
                                                                                      target="_blank"
                                                                                      ><span
                                                                                        style="
                                                                                          font-size: 15px;
                                                                                        "
                                                                                        >https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span
                                                                                      ></a
                                                                                    ></span
                                                                                  ></span
                                                                                ></span
                                                                              ></span
                                                                            ></span
                                                                          >.</span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          >If
                                                                          you
                                                                          are
                                                                          located
                                                                          in
                                                                          Switzerland,
                                                                          the
                                                                          contact
                                                                          details
                                                                          for
                                                                          the
                                                                          data
                                                                          protection
                                                                          authorities
                                                                          are
                                                                          available
                                                                          here:
                                                                          <span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                              ><span
                                                                                data-custom-class="body_text"
                                                                                ><span
                                                                                  style="
                                                                                    color: rgb(
                                                                                      48,
                                                                                      48,
                                                                                      241
                                                                                    );
                                                                                  "
                                                                                  ><span
                                                                                    data-custom-class="body_text"
                                                                                    ><span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      ><a
                                                                                        data-custom-class="link"
                                                                                        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                        rel="noopener noreferrer"
                                                                                        target="_blank"
                                                                                        >https://www.edoeb.admin.ch/edoeb/en/home.html</a
                                                                                      ></span
                                                                                    ></span
                                                                                  ></span
                                                                                ></span
                                                                              ></span
                                                                            ></span
                                                                          >.</span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="withdrawconsent"
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          <strong
                                                                            ><u
                                                                              >Withdrawing
                                                                              your
                                                                              consent:</u
                                                                            ></strong
                                                                          >
                                                                          If we
                                                                          are
                                                                          relying
                                                                          on
                                                                          your
                                                                          consent
                                                                          to
                                                                          process
                                                                          your
                                                                          personal
                                                                          information,
                                                                          <bdt
                                                                            class="
                                                                              block-component
                                                                            "
                                                                          ></bdt>
                                                                          <bdt
                                                                            class="
                                                                              else-block
                                                                            "
                                                                          ></bdt>
                                                                          you
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          withdraw
                                                                          your
                                                                          consent
                                                                          at any
                                                                          time.
                                                                          You
                                                                          can
                                                                          withdraw
                                                                          your
                                                                          consent
                                                                          at any
                                                                          time
                                                                          by
                                                                          contacting
                                                                          us by
                                                                          using
                                                                          the
                                                                          contact
                                                                          details
                                                                          provided
                                                                          in the
                                                                          section
                                                                          "
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <a
                                                                      data-custom-class="link"
                                                                      href="#contact"
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            data-custom-class="body_text"
                                                                            >HOW
                                                                            CAN
                                                                            YOU
                                                                            CONTACT
                                                                            US
                                                                            ABOUT
                                                                            THIS
                                                                            NOTICE?</span
                                                                          ></span
                                                                        ></span
                                                                      ></a
                                                                    >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          "
                                                                          below
                                                                          <bdt
                                                                            class="
                                                                              block-component
                                                                            "
                                                                          ></bdt>
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                    >
                                                                      <span
                                                                        data-custom-class="body_text"
                                                                      >
                                                                        However,
                                                                        please
                                                                        note
                                                                        that
                                                                        this
                                                                        will not
                                                                        affect
                                                                        the
                                                                        lawfulness
                                                                        of the
                                                                        processing
                                                                        before
                                                                        its
                                                                        withdrawal,
                                                                        nor
                                                                        <bdt
                                                                          class="
                                                                            block-component
                                                                          "
                                                                        ></bdt>
                                                                        <bdt
                                                                          class="
                                                                            else-block
                                                                          "
                                                                        ></bdt>
                                                                        will it
                                                                        affect
                                                                        the
                                                                        processing
                                                                        of your
                                                                        personal
                                                                        information
                                                                        conducted
                                                                        in
                                                                        reliance
                                                                        on
                                                                        lawful
                                                                        processing
                                                                        grounds
                                                                        other
                                                                        than
                                                                        consent.
                                                                        <bdt
                                                                          class="
                                                                            block-component
                                                                          "
                                                                        ></bdt>
                                                                      </span>
                                                                    </span>
                                                                    <bdt
                                                                      class="
                                                                        block-component
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                        ></span
                                                                      ></span>
                                                                    </bdt>
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          <span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                "
                                                                              >
                                                                                <span
                                                                                  style="
                                                                                    color: rgb(
                                                                                      89,
                                                                                      89,
                                                                                      89
                                                                                    );
                                                                                  "
                                                                                >
                                                                                  <span
                                                                                    data-custom-class="body_text"
                                                                                  >
                                                                                    <span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                    >
                                                                                      <span
                                                                                        style="
                                                                                          color: rgb(
                                                                                            89,
                                                                                            89,
                                                                                            89
                                                                                          );
                                                                                        "
                                                                                      >
                                                                                        <bdt
                                                                                          class="
                                                                                            block-component
                                                                                          "
                                                                                        >
                                                                                        </bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                    <bdt
                                                                      class="
                                                                        block-component
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                        ></span
                                                                      ></span>
                                                                    </bdt>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      data-custom-class="body_text"
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                        "
                                                                      >
                                                                        If you
                                                                        have
                                                                        questions
                                                                        or
                                                                        comments
                                                                        about
                                                                        your
                                                                        privacy
                                                                        rights,
                                                                        you may
                                                                        email us
                                                                        at
                                                                        <bdt
                                                                          class="
                                                                            question
                                                                          "
                                                                          >woojin.kid@gmail.com</bdt
                                                                        >
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                    <bdt
                                                                      class="
                                                                        statement-end-if-in-editor
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                        ></span
                                                                      ></span>
                                                                    </bdt>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="DNT"
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        color: rgb(
                                                                          127,
                                                                          127,
                                                                          127
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              id="control"
                                                                              style="
                                                                                color: rgb(
                                                                                  0,
                                                                                  0,
                                                                                  0
                                                                                );
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  data-custom-class="heading_1"
                                                                                  >8.
                                                                                  CONTROLS
                                                                                  FOR
                                                                                  DO-NOT-TRACK
                                                                                  FEATURES</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          >Most
                                                                          web
                                                                          browsers
                                                                          and
                                                                          some
                                                                          mobile
                                                                          operating
                                                                          systems
                                                                          and
                                                                          mobile
                                                                          applications
                                                                          include
                                                                          a
                                                                          Do-Not-Track
                                                                          ("DNT")
                                                                          feature
                                                                          or
                                                                          setting
                                                                          you
                                                                          can
                                                                          activate
                                                                          to
                                                                          signal
                                                                          your
                                                                          privacy
                                                                          preference
                                                                          not to
                                                                          have
                                                                          data
                                                                          about
                                                                          your
                                                                          online
                                                                          browsing
                                                                          activities
                                                                          monitored
                                                                          and
                                                                          collected.
                                                                          At
                                                                          this
                                                                          stage
                                                                          no
                                                                          uniform
                                                                          technology
                                                                          standard
                                                                          for
                                                                          recognizing
                                                                          and
                                                                          implementing
                                                                          DNT
                                                                          signals
                                                                          has
                                                                          been
                                                                          finalized.
                                                                          As
                                                                          such,
                                                                          we do
                                                                          not
                                                                          currently
                                                                          respond
                                                                          to DNT
                                                                          browser
                                                                          signals
                                                                          or any
                                                                          other
                                                                          mechanism
                                                                          that
                                                                          automatically
                                                                          communicates
                                                                          your
                                                                          choice
                                                                          not to
                                                                          be
                                                                          tracked
                                                                          online.
                                                                          If a
                                                                          standard
                                                                          for
                                                                          online
                                                                          tracking
                                                                          is
                                                                          adopted
                                                                          that
                                                                          we
                                                                          must
                                                                          follow
                                                                          in the
                                                                          future,
                                                                          we
                                                                          will
                                                                          inform
                                                                          you
                                                                          about
                                                                          that
                                                                          practice
                                                                          in a
                                                                          revised
                                                                          version
                                                                          of
                                                                          this
                                                                          privacy
                                                                          notice.</span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="caresidents"
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        color: rgb(
                                                                          127,
                                                                          127,
                                                                          127
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              id="control"
                                                                              style="
                                                                                color: rgb(
                                                                                  0,
                                                                                  0,
                                                                                  0
                                                                                );
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  data-custom-class="heading_1"
                                                                                  >9.
                                                                                  DO
                                                                                  CALIFORNIA
                                                                                  RESIDENTS
                                                                                  HAVE
                                                                                  SPECIFIC
                                                                                  PRIVACY
                                                                                  RIGHTS?</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          ><strong
                                                                            ><em
                                                                              >In
                                                                              Short:&nbsp;</em
                                                                            ></strong
                                                                          ><em
                                                                            >Yes,
                                                                            if
                                                                            you
                                                                            are
                                                                            a
                                                                            resident
                                                                            of
                                                                            California,
                                                                            you
                                                                            are
                                                                            granted
                                                                            specific
                                                                            rights
                                                                            regarding
                                                                            access
                                                                            to
                                                                            your
                                                                            personal
                                                                            information.</em
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          >California
                                                                          Civil
                                                                          Code
                                                                          Section
                                                                          1798.83,
                                                                          also
                                                                          known
                                                                          as the
                                                                          "Shine
                                                                          The
                                                                          Light"
                                                                          law,
                                                                          permits
                                                                          our
                                                                          users
                                                                          who
                                                                          are
                                                                          California
                                                                          residents
                                                                          to
                                                                          request
                                                                          and
                                                                          obtain
                                                                          from
                                                                          us,
                                                                          once a
                                                                          year
                                                                          and
                                                                          free
                                                                          of
                                                                          charge,
                                                                          information
                                                                          about
                                                                          categories
                                                                          of
                                                                          personal
                                                                          information
                                                                          (if
                                                                          any)
                                                                          we
                                                                          disclosed
                                                                          to
                                                                          third
                                                                          parties
                                                                          for
                                                                          direct
                                                                          marketing
                                                                          purposes
                                                                          and
                                                                          the
                                                                          names
                                                                          and
                                                                          addresses
                                                                          of all
                                                                          third
                                                                          parties
                                                                          with
                                                                          which
                                                                          we
                                                                          shared
                                                                          personal
                                                                          information
                                                                          in the
                                                                          immediately
                                                                          preceding
                                                                          calendar
                                                                          year.
                                                                          If you
                                                                          are a
                                                                          California
                                                                          resident
                                                                          and
                                                                          would
                                                                          like
                                                                          to
                                                                          make
                                                                          such a
                                                                          request,
                                                                          please
                                                                          submit
                                                                          your
                                                                          request
                                                                          in
                                                                          writing
                                                                          to us
                                                                          using
                                                                          the
                                                                          contact
                                                                          information
                                                                          provided
                                                                          below.</span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          If you
                                                                          are
                                                                          under
                                                                          18
                                                                          years
                                                                          of
                                                                          age,
                                                                          reside
                                                                          in
                                                                          California,
                                                                          and
                                                                          have a
                                                                          registered
                                                                          account
                                                                          with
                                                                          Services,
                                                                          you
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          request
                                                                          removal
                                                                          of
                                                                          unwanted
                                                                          data
                                                                          that
                                                                          you
                                                                          publicly
                                                                          post
                                                                          on the
                                                                          Services.
                                                                          To
                                                                          request
                                                                          removal
                                                                          of
                                                                          such
                                                                          data,
                                                                          please
                                                                          contact
                                                                          us
                                                                          using
                                                                          the
                                                                          contact
                                                                          information
                                                                          provided
                                                                          below
                                                                          and
                                                                          include
                                                                          the
                                                                          email
                                                                          address
                                                                          associated
                                                                          with
                                                                          your
                                                                          account
                                                                          and a
                                                                          statement
                                                                          that
                                                                          you
                                                                          reside
                                                                          in
                                                                          California.
                                                                          We
                                                                          will
                                                                          make
                                                                          sure
                                                                          the
                                                                          data
                                                                          is not
                                                                          publicly
                                                                          displayed
                                                                          on the
                                                                          Services,
                                                                          but
                                                                          please
                                                                          be
                                                                          aware
                                                                          that
                                                                          the
                                                                          data
                                                                          may
                                                                          not be
                                                                          completely
                                                                          or
                                                                          comprehensively
                                                                          removed
                                                                          from
                                                                          all
                                                                          our
                                                                          systems
                                                                          (e.g.,
                                                                          backups,
                                                                          etc.).
                                                                          <span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              data-custom-class="body_text"
                                                                            >
                                                                              <bdt
                                                                                class="
                                                                                  block-component
                                                                                "
                                                                              ></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="policyupdates"
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        color: rgb(
                                                                          127,
                                                                          127,
                                                                          127
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              id="control"
                                                                              style="
                                                                                color: rgb(
                                                                                  0,
                                                                                  0,
                                                                                  0
                                                                                );
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  data-custom-class="heading_1"
                                                                                  >10.
                                                                                  DO
                                                                                  WE
                                                                                  MAKE
                                                                                  UPDATES
                                                                                  TO
                                                                                  THIS
                                                                                  NOTICE?</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          ><em
                                                                            ><strong
                                                                              >In
                                                                              Short:&nbsp;</strong
                                                                            >Yes,
                                                                            we
                                                                            will
                                                                            update
                                                                            this
                                                                            notice
                                                                            as
                                                                            necessary
                                                                            to
                                                                            stay
                                                                            compliant
                                                                            with
                                                                            relevant
                                                                            laws.</em
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          >We
                                                                          may
                                                                          update
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          from
                                                                          time
                                                                          to
                                                                          time.
                                                                          The
                                                                          updated
                                                                          version
                                                                          will
                                                                          be
                                                                          indicated
                                                                          by an
                                                                          updated
                                                                          "Revised"
                                                                          date
                                                                          and
                                                                          the
                                                                          updated
                                                                          version
                                                                          will
                                                                          be
                                                                          effective
                                                                          as
                                                                          soon
                                                                          as it
                                                                          is
                                                                          accessible.
                                                                          If we
                                                                          make
                                                                          material
                                                                          changes
                                                                          to
                                                                          this
                                                                          privacy
                                                                          notice,
                                                                          we may
                                                                          notify
                                                                          you
                                                                          either
                                                                          by
                                                                          prominently
                                                                          posting
                                                                          a
                                                                          notice
                                                                          of
                                                                          such
                                                                          changes
                                                                          or by
                                                                          directly
                                                                          sending
                                                                          you a
                                                                          notification.
                                                                          We
                                                                          encourage
                                                                          you to
                                                                          review
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          frequently
                                                                          to be
                                                                          informed
                                                                          of how
                                                                          we are
                                                                          protecting
                                                                          your
                                                                          information.</span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="contact"
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        color: rgb(
                                                                          127,
                                                                          127,
                                                                          127
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              id="control"
                                                                              style="
                                                                                color: rgb(
                                                                                  0,
                                                                                  0,
                                                                                  0
                                                                                );
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  data-custom-class="heading_1"
                                                                                  >11.
                                                                                  HOW
                                                                                  CAN
                                                                                  YOU
                                                                                  CONTACT
                                                                                  US
                                                                                  ABOUT
                                                                                  THIS
                                                                                  NOTICE?</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          If you
                                                                          have
                                                                          questions
                                                                          or
                                                                          comments
                                                                          about
                                                                          this
                                                                          notice,
                                                                          you
                                                                          may
                                                                          <span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              data-custom-class="body_text"
                                                                            >
                                                                              <bdt
                                                                                class="
                                                                                  block-component
                                                                                "
                                                                              ></bdt>
                                                                              email
                                                                              us
                                                                              at
                                                                              <bdt
                                                                                class="
                                                                                  question
                                                                                "
                                                                                >woojin.kid@gmail.com
                                                                              </bdt>
                                                                              <bdt
                                                                                class="
                                                                                  statement-end-if-in-editor
                                                                                "
                                                                              >
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                          <span
                                                                            style="
                                                                              font-size: 15px;
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                font-size: 15px;
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                              ><span
                                                                                data-custom-class="body_text"
                                                                                >&nbsp;or
                                                                                by
                                                                                post
                                                                                to:</span
                                                                              ></span
                                                                            ></span
                                                                          >
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          <span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: rgb(
                                                                                    89,
                                                                                    89,
                                                                                    89
                                                                                  );
                                                                                "
                                                                              >
                                                                                <span
                                                                                  data-custom-class="body_text"
                                                                                >
                                                                                  <bdt
                                                                                    class="
                                                                                      question
                                                                                    "
                                                                                    >Yuni
                                                                                    App</bdt
                                                                                  >
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          <span
                                                                            style="
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                          >
                                                                            <span
                                                                              data-custom-class="body_text"
                                                                            >
                                                                              <bdt
                                                                                class="
                                                                                  block-component
                                                                                "
                                                                              ></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                    >
                                                                      <span
                                                                        data-custom-class="body_text"
                                                                      >
                                                                        <bdt
                                                                          class="
                                                                            question
                                                                          "
                                                                          >Helene-Mayer-Ring
                                                                          7A</bdt
                                                                        >
                                                                        <span
                                                                          style="
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                        >
                                                                          <span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <bdt
                                                                              class="
                                                                                block-component
                                                                              "
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                    >
                                                                      <span
                                                                        data-custom-class="body_text"
                                                                      >
                                                                        <bdt
                                                                          class="
                                                                            question
                                                                          "
                                                                          >Munich</bdt
                                                                        >
                                                                        <span
                                                                          style="
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                        >
                                                                          <span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                          >
                                                                            <bdt
                                                                              class="
                                                                                block-component
                                                                              "
                                                                            ></bdt>
                                                                            ,
                                                                            <bdt
                                                                              class="
                                                                                question
                                                                              "
                                                                              >Bavaria</bdt
                                                                            >
                                                                            <bdt
                                                                              class="
                                                                                statement-end-if-in-editor
                                                                              "
                                                                            ></bdt>
                                                                            <bdt
                                                                              class="
                                                                                block-component
                                                                              "
                                                                            ></bdt>
                                                                            <bdt
                                                                              class="
                                                                                question
                                                                              "
                                                                              >80809</bdt
                                                                            >
                                                                            <bdt
                                                                              class="
                                                                                statement-end-if-in-editor
                                                                              "
                                                                            ></bdt>
                                                                            <bdt
                                                                              class="
                                                                                block-component
                                                                              "
                                                                            ></bdt>
                                                                            <bdt
                                                                              class="
                                                                                block-component
                                                                              "
                                                                            ></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                    >
                                                                      <span
                                                                        data-custom-class="body_text"
                                                                      >
                                                                        <span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                        >
                                                                          <span
                                                                            data-custom-class="body_text"
                                                                          >
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                            >
                                                                              <bdt
                                                                                class="
                                                                                  block-component
                                                                                "
                                                                              ></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt
                                                                          class="
                                                                            question
                                                                          "
                                                                          >Germany</bdt
                                                                        >
                                                                        <span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                        >
                                                                          <span
                                                                            data-custom-class="body_text"
                                                                          >
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                            >
                                                                              <bdt
                                                                                class="
                                                                                  statement-end-if-in-editor
                                                                                "
                                                                              >
                                                                                <span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                >
                                                                                  <span
                                                                                    data-custom-class="body_text"
                                                                                  >
                                                                                    <span
                                                                                      style="
                                                                                        color: rgb(
                                                                                          89,
                                                                                          89,
                                                                                          89
                                                                                        );
                                                                                      "
                                                                                    >
                                                                                      <bdt
                                                                                        class="
                                                                                          statement-end-if-in-editor
                                                                                        "
                                                                                      >
                                                                                        <span
                                                                                          style="
                                                                                            font-size: 15px;
                                                                                          "
                                                                                        >
                                                                                          <span
                                                                                            data-custom-class="body_text"
                                                                                          >
                                                                                            <span
                                                                                              style="
                                                                                                color: rgb(
                                                                                                  89,
                                                                                                  89,
                                                                                                  89
                                                                                                );
                                                                                              "
                                                                                            >
                                                                                              <bdt
                                                                                                class="
                                                                                                  statement-end-if-in-editor
                                                                                                "
                                                                                              >
                                                                                              </bdt>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </bdt>
                                                                                      <bdt
                                                                                        class="
                                                                                          statement-end-if-in-editor
                                                                                        "
                                                                                      >
                                                                                      </bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                                <span
                                                                                  data-custom-class="body_text"
                                                                                >
                                                                                  <span
                                                                                    style="
                                                                                      color: rgb(
                                                                                        89,
                                                                                        89,
                                                                                        89
                                                                                      );
                                                                                    "
                                                                                  >
                                                                                    <span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                    >
                                                                                      <bdt
                                                                                        class="
                                                                                          statement-end-if-in-editor
                                                                                        "
                                                                                      >
                                                                                      </bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <span
                                                                        data-custom-class="body_text"
                                                                      >
                                                                        <span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                        >
                                                                          <span
                                                                            data-custom-class="body_text"
                                                                          >
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                              "
                                                                            >
                                                                              <bdt
                                                                                class="
                                                                                  statement-end-if-in-editor
                                                                                "
                                                                              >
                                                                                <span
                                                                                  style="
                                                                                    color: rgb(
                                                                                      89,
                                                                                      89,
                                                                                      89
                                                                                    );
                                                                                  "
                                                                                >
                                                                                  <span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                  >
                                                                                    <span
                                                                                      data-custom-class="body_text"
                                                                                    >
                                                                                      <bdt
                                                                                        class="
                                                                                          block-component
                                                                                        "
                                                                                      >
                                                                                        <bdt
                                                                                          class="
                                                                                            block-component
                                                                                          "
                                                                                        >
                                                                                        </bdt>
                                                                                      </bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                        >
                                                                          <span
                                                                            data-custom-class="body_text"
                                                                          >
                                                                            <span
                                                                              style="
                                                                                color: rgb(
                                                                                  89,
                                                                                  89,
                                                                                  89
                                                                                );
                                                                                font-size: 15px;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  font-size: 15px;
                                                                                "
                                                                              >
                                                                                <span
                                                                                  data-custom-class="body_text"
                                                                                >
                                                                                  <bdt
                                                                                    class="
                                                                                      statement-end-if-in-editor
                                                                                    "
                                                                                  >
                                                                                    <bdt
                                                                                      class="
                                                                                        block-component
                                                                                      "
                                                                                    ></bdt>
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    id="request"
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        color: rgb(
                                                                          127,
                                                                          127,
                                                                          127
                                                                        );
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                            color: rgb(
                                                                              89,
                                                                              89,
                                                                              89
                                                                            );
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                              color: rgb(
                                                                                89,
                                                                                89,
                                                                                89
                                                                              );
                                                                            "
                                                                            ><span
                                                                              id="control"
                                                                              style="
                                                                                color: rgb(
                                                                                  0,
                                                                                  0,
                                                                                  0
                                                                                );
                                                                              "
                                                                              ><strong
                                                                                ><span
                                                                                  data-custom-class="heading_1"
                                                                                  >12.
                                                                                  HOW
                                                                                  CAN
                                                                                  YOU
                                                                                  REVIEW,
                                                                                  UPDATE,
                                                                                  OR
                                                                                  DELETE
                                                                                  THE
                                                                                  DATA
                                                                                  WE
                                                                                  COLLECT
                                                                                  FROM
                                                                                  YOU?</span
                                                                                ></strong
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        font-size: 15px;
                                                                        color: rgb(
                                                                          89,
                                                                          89,
                                                                          89
                                                                        );
                                                                      "
                                                                    >
                                                                      <span
                                                                        style="
                                                                          font-size: 15px;
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                      >
                                                                        <span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          Based
                                                                          on the
                                                                          applicable
                                                                          laws
                                                                          of
                                                                          your
                                                                          country,
                                                                          you
                                                                          may
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          request
                                                                          access
                                                                          to the
                                                                          personal
                                                                          information
                                                                          we
                                                                          collect
                                                                          from
                                                                          you,
                                                                          change
                                                                          that
                                                                          information,
                                                                          or
                                                                          delete
                                                                          it in
                                                                          some
                                                                          circumstances.
                                                                          To
                                                                          request
                                                                          to
                                                                          review,
                                                                          update,
                                                                          or
                                                                          delete
                                                                          your
                                                                          personal
                                                                          information,
                                                                          please
                                                                          <bdt
                                                                            class="
                                                                              block-component
                                                                            "
                                                                          ></bdt>
                                                                          submit
                                                                          a
                                                                          request
                                                                          form
                                                                          by
                                                                          clicking&nbsp;
                                                                        </span>
                                                                        <span
                                                                          style="
                                                                            color: rgb(
                                                                              48,
                                                                              48,
                                                                              241
                                                                            );
                                                                          "
                                                                          ><span
                                                                            data-custom-class="body_text"
                                                                            ><span
                                                                              style="
                                                                                font-size: 15px;
                                                                              "
                                                                              ><a
                                                                                data-custom-class="link"
                                                                                href="https://app.termly.io/notify/3f5abc02-55c6-4d13-a08f-9baa84bbe369"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                                >here</a
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        >
                                                                        <bdt
                                                                          class="
                                                                            block-component
                                                                          "
                                                                          ><span
                                                                            data-custom-class="body_text"
                                                                          ></span
                                                                        ></bdt>
                                                                      </span>
                                                                    </span>
                                                                    <span
                                                                      data-custom-class="body_text"
                                                                      >.</span
                                                                    >
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  style="
                                                                    color: #595959;
                                                                    font-size: 14px;
                                                                    font-family: Arial;
                                                                    padding-top: 16px;
                                                                  "
                                                                >
                                                                  This privacy
                                                                  policy was
                                                                  created using
                                                                  Termly's
                                                                  <a
                                                                    style="
                                                                      color: rgb(
                                                                        48,
                                                                        48,
                                                                        241
                                                                      ) !important;
                                                                    "
                                                                    href="https://termly.io/products/privacy-policy-generator"
                                                                    >Privacy
                                                                    Policy
                                                                    Generator</a
                                                                  >.
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BottomBar />
  </div>
</template>

<script>
import NavBar from "../NavBar.vue";
import BottomBar from "../BottomBar.vue";

export default {
  components: {
    NavBar,
    BottomBar,
  },
};
</script>


<style scoped>
* {
  text-align: left;
}

[data-custom-class="body"] {
  padding: 0 58px;
  padding-bottom: 50px;
}

[data-custom-class="body"],
[data-custom-class="body"] * {
  background: transparent !important;
}

[data-custom-class="title"],
[data-custom-class="title"] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}

[data-custom-class="subtitle"],
[data-custom-class="subtitle"] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}

[data-custom-class="heading_1"],
[data-custom-class="heading_1"] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}

[data-custom-class="heading_2"],
[data-custom-class="heading_2"] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}

[data-custom-class="body_text"],
[data-custom-class="body_text"] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}

[data-custom-class="link"],
[data-custom-class="link"] * {
  color: #3030f1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}
</style>