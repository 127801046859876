<template>
  <div>
    <NavBar />
    <div style="height: 50px"></div>
    <div data-custom-class="body">
      <div>
        <strong
          ><span style="font-size: 26px"
            ><span data-custom-class="title">LEGAL NOTICE</span></span
          ></strong
        >
      </div>
      <div><br /></div>

      <div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <strong
            ><span style="font-size: 15px"
              ><span data-custom-class="heading_1"
                >In Accordance with Section 5 TMG</span
              ></span
            ></strong
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              Woojin Ko <br />
              Helene-Mayer-Ring 7A <br />
              Munich , Bavaria 80809 <br />
              Germany <br />
              info@yuni-app.com
            </span>
          </span>
        </div>

        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <strong
            ><span style="font-size: 15px"
              ><span data-custom-class="heading_1"
                >Accountability for content</span
              ></span
            ></strong
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              The contents of our pages have been created with the utmost care.
              However, we cannot guarantee the content’s accuracy, completeness
              or topicality. According to statutory provisions, we are
              furthermore responsible for our own content on these web pages. In
              this context, please not that we are accordingly not obliged to
              monitor merely the transmitted or saved information of third
              parties, or investigate circumstances pointing to illegal
              activity. Our obligations to remove or block the use of
              information under generally applicable laws remain unaffected by
              this as per §§ 8 to 10 of the Telemedia Act (TMG).
            </span>
          </span>
        </div>

        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <strong
            ><span style="font-size: 15px"
              ><span data-custom-class="heading_1"
                >Accountability for links</span
              ></span
            ></strong
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              Responsibility for the content of external links (to web pages of
              third parties) lies solely with the operators of the linked pages.
              No violations were evident to us at the time of linking. Should
              any legal infringement become known to us, we will remove the
              respective link immediately.
            </span>
          </span>
        </div>

        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <strong
            ><span style="font-size: 15px"
              ><span data-custom-class="heading_1">Copyright</span></span
            ></strong
          >
        </div>
        <div style="line-height: 1.5"><br /></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px">
            <span data-custom-class="body_text">
              Our web pages and their contents are subject to German copyright
              law. Unless expressly permitted by law (§ 44a et seq. of the
              copyright law), every form of utilizing, reproducing or processing
              works subject to copyright protection on our web pages requires
              the prior consent of the respective owner of the rights.
              Individual reproductions of a work are allowed only for private
              use, so must not serve either directly or indirectly for earnings.
              Unauthorized utilization of copyrighted works is punishable (§ 106
              of the copyright law).
            </span>
          </span>
        </div>
      </div>
    </div>
    <BottomBar />
  </div>
</template>

<script>
import NavBar from "../NavBar.vue";
import BottomBar from "../BottomBar.vue";

export default {
  components: {
    NavBar,
    BottomBar,
  },
};
</script>


<style scoped>
* {
  text-align: left;
}

[data-custom-class="body"] {
  padding: 0 58px;
  padding-bottom: 50px;
}

[data-custom-class="body"],
[data-custom-class="body"] * {
  background: transparent !important;
}

[data-custom-class="title"],
[data-custom-class="title"] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}

[data-custom-class="subtitle"],
[data-custom-class="subtitle"] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}

[data-custom-class="heading_1"],
[data-custom-class="heading_1"] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}

[data-custom-class="heading_2"],
[data-custom-class="heading_2"] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}

[data-custom-class="body_text"],
[data-custom-class="body_text"] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}

[data-custom-class="link"],
[data-custom-class="link"] * {
  color: #3030f1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}
</style>