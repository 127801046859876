<template>
  <div id="app">
    <router-view></router-view>
    <Dialog/>
  </div>
</template>

<script>
import Dialog from './components/Dialog.vue'

export default {
  name: 'App',
  components: {
    Dialog,
  },
  method: {
    showDialog(){
      this.showDialog = true;
    },
    hideDialog(){
      this.showDialog = false;
    }
  },
  metaInfo: {
    title: "united.",
    meta: [
      {
        name: "description",
        content:
          "Welcome to united. Meet the students around you. Never miss a thing at university. We bring university life back to you."
      }
    ]
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

#app {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 100px;
}

* {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

/* .indented {
  padding-left: 105px;
} */

@media only screen and (max-width: 1000px) {
  .indented {
    padding-left: 0;
    align-content: center;
  }
}

* {
  overflow-y: visible;
  overflow-x: hidden;
}

html {
  background: #F2DDF8;
}

#title{
    user-select: none;
}

</style>
