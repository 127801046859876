<template>
  <div class="background" id="component1">
    <div>
      <div id="title">
        Time for yuni.
      </div>
      <div style="height: 25px"></div>
      <div id="description">
        Sign up now and discover amazing students around you!<br/>
Available for TUM students only (for now).
      </div>
      <div style="height: 45px"></div>
      <div class="row">
        <img class="store" src="../../assets/appstore.webp" alt />
        <div style="width: 25px; height: 25px"></div>
        <a href="https://play.google.com/store/apps/details?id=com.united_app.united" target="_blank">
        <img class="store" src="../../assets/googleplay.webp" alt />
        </a>
      </div>
      <div id="placeholder"></div>
    </div>
    <div style="min-width: 60px"></div>
    <div>
      <img style="height:600px" src="../../assets/yuni_cards.webp" alt />
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentOne",
  methods: {
    showDialog(){
      var element = document.getElementById('overlay');
      element.style.display = 'flex';
    }
  }
};
</script>

<style scoped>
.background {
  padding-top: 40px;
  padding-bottom: 70px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 105px;
  padding-right: 105px;
}


#placeholder {
  height: 20px;
}

#title {
  color: #2B2A35;
  font-size: 60px;
  font-weight: 800;
  text-align: left;
  white-space: nowrap;
}

#description {
  color: #2B2A35;
  font-size: 20px;
  text-align: left;
  line-height: 150%;
}

.row {
  display: flex;
}

#download-button {
  text-align: center;
  border-radius: 100px;
  color: black;
  font-weight: 600;
  padding: 15px 30px;
  background: #18e299;
  user-select: none;
  cursor: pointer;
}

.store {
  width: 200px;
  align-self: flex-start;
}

@media only screen and (max-width: 1000px) {
  .background {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  #placeholder {
    height: 50px;
  }

  #title, #description {
    text-align: center;
  }

  .row {
    justify-content: center;
  }

  .store{
    align-self: auto;
  }
}

@media only screen and (max-width: 600px) {
  #title {
    font-size: 40px;
  }

  .row {
    flex-direction: column;
    align-items: center;
  }
}
</style>