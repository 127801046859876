import Vue from 'vue'
import App from './App.vue'
import { router } from "./routes"
import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false

//Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: "G-DTTV48YE0L"
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
