<template>
  <div>
    <NavBar/>
    <ComponentOne/>
    <ComponentTwo/>
    <ComponentThree/>
    <Supporters/>
    <BottomBar/>
    
  </div>
</template>

<script>
import NavBar from '../NavBar.vue'
import ComponentOne from './ComponentOne.vue'
import ComponentTwo from './ComponentTwo.vue'
import ComponentThree from './ComponentThree.vue'
import BottomBar from '../BottomBar.vue'
import Supporters from './Supporters.vue'

export default {
  name: "Home",
  components: {
    NavBar,
    ComponentOne,
    ComponentTwo,
    ComponentThree,
    BottomBar,
    Supporters,
  }
};
</script>

<style>

</style>