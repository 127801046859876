<template>
  <div class="background" id="component1">
    <div>
      <div id="title">Supported universities.</div>
      <div style="height: 25px"></div>
      <div id="description">
        At the moment, we only support students from the Technical University of
        Munich.
      </div>
      <div style="height: 45px"></div>
      <div class="row">
        <a
          href="mailto:business@yuni-app.com"
          target="_blank"
          id="download-button"
          >Request university</a
        >
      </div>

      <div id="placeholder"></div>
    </div>
    <div style="min-width: 60px"></div>
    <div>
      <img style="width: 200px" src="../../assets/tum_logo.svg" alt />
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentThree",
  methods: {
    showDialog() {
      var element = document.getElementById("overlay");
      element.style.display = "flex";
    },
  },
};
</script>

<style scoped>
.background {
  padding-top: 120px;
  padding-bottom: 120px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 105px;
  padding-right: 105px;
}

#placeholder {
  height: 20px;
}

#title {
  color: #2b2a35;
  font-size: 60px;
  font-weight: 800;
  text-align: left;
}

#description {
  color: #2b2a35;
  font-size: 20px;
  text-align: left;
  line-height: 150%;
}

.row {
  display: flex;
}

#download-button {
  text-align: center;
  border-radius: 100px;
  color: white;
  font-weight: 600;
  padding: 15px 30px;
  background: #2b2a35;
  user-select: none;
  cursor: pointer;
}

.store {
  width: 200px;
  align-self: flex-start;
}

@media only screen and (max-width: 1000px) {
  .background {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  #placeholder {
    height: 50px;
  }

  #title,
  #description {
    text-align: center;
  }

  .row {
    justify-content: center;
  }

  .store {
    align-self: auto;
  }

  img {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 600px) {
  #title {
    font-size: 40px;
  }

  .row {
    flex-direction: column;
    align-items: center;
  }
}
</style>