<template>
  <div id="background">
    <div style="height: 20px"></div>
    <div id="title">Our supporters</div>
    <div style="height: 20px"></div>
    <div id="description">Thank you for your help!</div>
    <div style="height: 20px"></div>
    <div id="supporters">
      <img style="height: 90px" src="../../assets/unternehmertum.svg" alt />
      <div style="width: 60px"></div>
      <img style="height: 90px" src="../../assets/tum_logo_white.svg" alt />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#background {
  padding-top: 50px;
  background: #2b2a35;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding-right: 105px;
  padding-left: 105px;
}

#title {
  font-size: 50px;
  font-weight: 700;
}

#supporters {
  padding: 50px 0;
  display: flex;
  justify-content: center;
}

#description {
  font-size: 20px;
  line-height: 150%;
}

.row {
  display: flex;
}

.botlinks {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

@media only screen and (max-width: 1000px) {
  #background {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }

  #supporters {
    padding-right: 70px;
    padding-left: 70px;
  }

  #placeholder {
    height: 45px;
  }

  .botlinks {
    flex-direction: column;
  }
}
</style>