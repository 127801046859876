<template>
  <div>
    <NavBarMobile
      @callback="customhideSide"
      class="initialside"
      v-if="mobileView"
      id="side"
    />
    <div id="navbar">
      <div id="wrapper">
        <div id="title">
          <img
            v-if="!mobileView"
            class="iconlogo"
            src="../assets/yuni_website_logo.svg"
            alt
          />
          <img
            v-if="mobileView"
            class="iconlogo"
            src="../assets/yuni_only_logo.svg"
            alt
          />
        </div>
        <ol id="buttons">
          <div
            id="navigation-icon"
            v-if="mobileView"
            @click="
              showNav = !showNav;
              showhideSide(showNav);
            "
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div id="sizedbox"></div>
          <a @click="showDialog()" id="getourapp">Download</a>
          <router-link v-if="!mobileView" to="/about-us" class="navitem">About us</router-link>
          <router-link v-if="!mobileView" to="/" class="navitem">Home</router-link>
          
          <!-- <a
            @click="scrollMeTo('component1')"
            v-if="!mobileView"
            class="navitem"
            >Home</a
          > -->
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import NavBarMobile from "./NavBarMobile";

export default {
  name: "NavBar",
  data: () => {
    return {
      mobileView: false,
      showNav: false,
    };
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 1000;
    },
    showhideSide(current) {
      document.getElementById("navigation-icon").classList.toggle("open");
      if (current) {
        document.getElementById("side").classList.remove("noside");
        document.getElementById("side").classList.add("withside");
      } else {
        document.getElementById("side").classList.remove("withside");
        document.getElementById("side").classList.add("noside");
      }
    },
    customhideSide() {
      if (this.showNav) {
        this.showNav = false;
      }
    },
    scrollMeTo(refName) {
      // var element = this.$refs[refName];
      var element = document.getElementById(refName);
      //element.scrollIntoView({ behavior: "smooth", block:"start" });
      var top = element.offsetTop;
      window.scrollTo({ top: top - 100, behavior: "smooth" });
    },
    showDialog(){
      var element = document.getElementById('overlay');
      element.style.display = 'flex';
    }
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },
  components: {
    NavBarMobile,
  },
};
</script>

<style scoped>
.iconlogo {
  height: 43px;
}

#sizedbox {
  width: 25px;
}

#wrapper {
  height: 100px;
  background: #F2DDF8;
  padding: 0 58px 0 58px;
  /* padding: 0 4% 0 4%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  user-select: none;
  z-index: 2;
}

#title {
  align-items: center;
  font-size: 25px;
  display: flex;
  color: #707070;
}

#buttons {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.navitem {
  margin-right: 70px;
  color: #2B2A35;
  cursor: pointer;
  font-weight: 500;
}

#pp {
  margin: 0px 25px;
  color: #707070;
}

#getourapp {
  text-align: center;
  border-radius: 100px;
  margin-left: 15px;
  color: white;
  font-weight: 600;
  padding: 15px 40px;
  background: #2B2A35;
  white-space: nowrap;
  cursor: pointer;
}

#navigation-icon span {
  top: 5px;
  left: 2px;
  display: block;
  height: 2px;
  width: 19px;
  position: absolute;
  background: #2B2A35;
  border-radius: 1px;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#navigation-icon span:nth-child(1) {
  top: 6px;
}

#navigation-icon span:nth-child(2),
#navigation-icon span:nth-child(3) {
  top: 11px;
}

#navigation-icon span:nth-child(4) {
  top: 16px;
}

#navigation-icon.open span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

#navigation-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#navigation-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#navigation-icon.open span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}

#navigation-icon {
  position: relative;
  cursor: pointer;
  user-select: none;
  height: 24px;
  width: 24px;
}

.no-nav {
  transition: all 500ms ease-in-out;
  transform: translateY(-80px);
}

.with-nav {
  transition: all 500ms ease-in-out;
  transform: translateY(0px);
}

.initialside {
  transform: translateX(100%);
  transition: all 500ms ease-in-out;
}

.noside {
  transform: translateX(100%);
  transition: all 500ms ease-in-out;
}

.withside {
  transform: translateX(0px);
  transition: all 500ms ease-in-out;
}

@media only screen and (max-width: 600px) {
  #getourapp {
    display: none;
  }

  #wrapper {
    height: 90px;
    padding: 0 7% 0 7%;
  }

  .iconlogo {
    height: 27px;
    padding-bottom: 3px;
  }
}
</style>

<style>
a {
  text-decoration: none;
}
</style>

