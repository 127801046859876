<template>
  <div id="background">
    <div class="icons">
      <img src="../../assets/yuni_icons.svg" alt />
    </div>
    
    <div>
      <div id="title">
        How it works?
      </div>
      <div style="height: 25px"></div>
      <div id="description">
        Download our app, create a profile and tell us a little about yourself. <br/>
        Then, you can swipe on other students around you based on study program, interests and more! <br/>
        Once you have matched, you can start chatting with each other!
      </div>
      <div style="height: 30px"></div>
      <div class="row">
        <a @click="showDialog()" id="download-button">Get Yuni</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentTwo",
  methods: {
    showDialog(){
      var element = document.getElementById('overlay');
      element.style.display = 'flex';
    }
  }
};
</script>

<style scoped>

img {
    width: 100%;
    height: auto;
}

.icons{
  margin-right: 100px;
}

#background {
  padding-top: 120px;
  padding-bottom: 120px;
  background: #2B2A35;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 105px;
  padding-right: 105px;
}

#title {
  color: white;
  font-size: 60px;
  font-weight: 800;
  text-align: left;
}

#description {
  color: white;
  font-size: 20px;
  text-align: left;
  white-space: normal;
  line-height: 150%;
  /* max-width: 80%; */
}

.row {
  display: flex;
}

#download-button {
  text-align: center;
  border-radius: 100px;
  color: white;
  font-weight: 600;
  padding: 15px 30px;
  background: #5727f2;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  #background {
    flex-direction: column-reverse;
    padding-left: 20px;
    padding-right: 20px;
  }

  #placeholder {
    height: 45px;
  }

  #title, #description {
    text-align: center;
  }

  .icons{
    margin-top: 70px;
    margin-right: 0;
  }

  .row {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  #title {
    font-size: 25px;
  }
}
</style>