import Vue from 'vue'
import VueRouter from "vue-router"

import Home from "./components/Home/Home"
import AboutUs from "./components/AboutUs/AboutUs"
import MobilePrivacyPolicy from "./components/PrivacyPolicy/MobilePrivacyPolicy"
import WebPrivacyPolicy from "./components/PrivacyPolicy/WebPrivacyPolicy"
import LegalNotice from "./components/PrivacyPolicy/LegalNotice"

Vue.use(VueRouter)

export const router = new VueRouter({
    mode: "history",
    routes: [
        { path: "", component: Home },
        { path: "/home", component: Home },
        { path: "/about-us", component: AboutUs },
        { path: "/mobile-privacy-policy", component: MobilePrivacyPolicy}, 
        { path: "/privacy-policy", component: WebPrivacyPolicy}, 
        { path: "/legal-notice", component: LegalNotice}, 
    ],
    scrollBehavior() {
        // always scroll to top
        return { x: 0, y: 0 };
      },
});

