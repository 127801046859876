<template>
  <div>
    <NavBar/>
    <MeetOurTeam/>
    <BottomBar/>
  </div>
</template>

<script>
import NavBar from '../NavBar.vue'
import BottomBar from '../BottomBar.vue'
import MeetOurTeam from './MeetOurTeam.vue'

export default {
  name: "AboutUs",
  components: {
    NavBar,
    BottomBar,
    MeetOurTeam,
  }
};
</script>

<style>

</style>