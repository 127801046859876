<template>
  <div @click="hideDialog()" id="overlay">
    <div v-on:click.stop class="dialog">
      <img src="../assets/yuni_only_logo.svg" alt="" />
      <div style="height: 45px"></div>
      <div id="title">Join our closed beta!</div>
      <div style="height: 30px"></div>
      <div id="text">
        Contact us via info@yuni-app.com to <br />
        get exclusive first access.
      </div>
      <div style="height: 45px"></div>
      <div @click="hideDialog()" id="download-button">Got it!</div>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    hideDialog() {
      var element = document.getElementById("overlay");
      element.style.display = "none";
    },
  },
};
</script>

<style scoped>
#overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  display: none;
  justify-content: center;
  align-items: center;
}

.dialog {
  background: #F2DDF8;
  height: 450px;
  width: 450px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img {
  height: 50px;
}

#title {
  font-size: 30px;
  font-weight: 600;
}

#download-button {
  text-align: center;
  border-radius: 100px;
  color: white;
  font-weight: 600;
  padding: 15px 30px;
  background: #5727f2;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .dialog {
      width: 350px;
  }
}
</style>