<template>
  <ol class="buttons">
    <router-link to="/" class="navitem">Home</router-link>
    <router-link to="/about-us" class="navitem">About us</router-link>
  </ol>
</template>

<script>
export default {
  name: "NavBarMobile",
  methods: {
    scrollMeTo(refName) {
      // var element = this.$refs[refName];
      var element = document.getElementById(refName);
      //element.scrollIntoView({ behavior: "smooth", block:"start" });
      var top = element.offsetTop;
      window.scrollTo({ top: top - 100, behavior: "smooth" });
    }
  }
};
</script>

<style scoped>
.buttons {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: auto;
  padding: 100px 60px;
  background: #2B2A35;
  z-index: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
  /*box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);*/
}

.navitem {
  margin: 25px 0px;
  user-select: none;
  font-weight: 600;
  color:white;
}

</style>